<template>
    <div v-if="isShow" class="maskMsg"></div>
    <div class="msgBox" :style="{ opacity: opacity }">{{ title }}</div>
</template>

<script setup>
import { ref } from 'vue';


const title = ref('通知')
const isShow = ref(false)
const opacity = ref(0)

let props = defineProps({
    hide: {
        type: Function,
        default: () => {}
    }
})

const show = (data) => {
    title.value = data.msg
    isShow.value = true
    setTimeout(() => {
        opacity.value = 1
    }, 10)
    
    setTimeout(() => {
        isShow.value = false
        opacity.value = 0
        setTimeout(() => {
            props.hide()
        }, 600)
    }, 2010)
}

defineExpose({
    show
})

</script>

<style>
.maskMsg{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
}
.msgBox {
    background: #2c3542a1;
    width: fit-content;
    position: fixed;
    z-index: 999;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: 600;
    padding: 6px 24px;
    border-radius: 12px;
    color: #ebebeb;
    transition: opacity 600ms;
}
</style>