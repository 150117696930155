import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import msg from '@/components/msg/msg.js'
import cookie from 'js-cookie'

const app = createApp(App)

app.config.globalProperties.$cookie = cookie
app.config.globalProperties.$axios = axios
axios.defaults.baseURL = '/netdisk'
axios.defaults.timeout = 5000

app.use(store).use(router).use(msg).mount('#app')
